import { createAsyncThunk } from '@reduxjs/toolkit'

import { fetchImpersonatedAuth } from 'api'

import { ImpersonationArgs, AuthTokensDTO } from 'types'

import { AUTH } from 'slices/constants'

import { getIsImpersonator } from './selectors'

import { RootState } from 'reduxStore'

export const impersonateWorkerThunk = createAsyncThunk(
  `${AUTH}/impersonateWorker`,
  async (impersonationArgs: ImpersonationArgs) => {
    const tokens: AuthTokensDTO = await fetchImpersonatedAuth(impersonationArgs)
    return tokens
  },
  {
    condition: (impersonationArgs: ImpersonationArgs, { getState }) => {
      const state = getState() as RootState
      const alreadyImpersonating = getIsImpersonator(state)
      if (alreadyImpersonating) return false
    },
    dispatchConditionRejection: true
  }
)
