import { Switch } from 'react-router-dom'

import JackpotHistory from 'pages/JackpotHistory'
import AchievedGoals from 'pages/achievedGoals'
import CelebrateOutbox from 'pages/admin/celebrateOutbox'
import CelebrateTeam from 'pages/admin/celebrateTeam'
import CSVSchemas from 'pages/admin/csv'
import AdminCSVForm from 'pages/admin/csv/CSVForm'
import AdminGoals from 'pages/admin/goals'
import AdminGoalsForm from 'pages/admin/goals/form'
import TeamMembers from 'pages/admin/team'
import TeamMemberEdit from 'pages/admin/team/teamMemberEdit'
import TeamMemberView from 'pages/admin/team/teamMemberView'
import AuthPage from 'pages/auth'
import ImpersonationAuthPage from 'pages/auth/impersonate'
import VerifyEmail from 'pages/auth/verifyEmail'
import Error404 from 'pages/error/error404'
import Goals from 'pages/goals'
import Help from 'pages/help'
import Home from 'pages/home'
import Jackpot from 'pages/jackpot'
import JackpotPrize from 'pages/jackpotPrize'
import LoginPage from 'pages/login'
import Perk from 'pages/perk'
import Perks from 'pages/perks'
import SuggestPerk from 'pages/perks/suggestPerk'
import RedeemedPerks from 'pages/redeemedPerks'
import UserProfile from 'pages/userProfile'

import ProtectedRoute from 'components/ProtectedRoute'
import PublicRoute from 'components/PublicRoute'
import AdminRoute from 'components/adminRoute'
import SnackBar from 'components/snackbar'
import TeamLeaderRoute from 'components/teamLeaderRoute'

import { AdminPaths, ChildPaths, RootPaths, TeamLeaderPaths } from 'utils/helpers'

import { LaunchDarklyProvider } from './LaunchDarklyProvider'

export default function App() {
  return (
    <>
      <SnackBar />
      <Switch>
        <PublicRoute path={RootPaths.welcome} component={LoginPage} />
        <PublicRoute exact path={RootPaths.loginToken} component={AuthPage} />
        <PublicRoute
          exact
          path={RootPaths.impersonate}
          component={ImpersonationAuthPage}
        />

        <LaunchDarklyProvider>
          <Switch>
            <AdminRoute exact path={AdminPaths.manageAllGoals}>
              <AdminGoals />
            </AdminRoute>
            <AdminRoute exact path={AdminPaths.manageUpsertGoal}>
              <AdminGoalsForm entityType="goal" />
            </AdminRoute>
            <AdminRoute exact path={AdminPaths.manageCSV}>
              <CSVSchemas />
            </AdminRoute>
            <AdminRoute exact path={AdminPaths.manageUpsertCSV}>
              <AdminCSVForm entityType="CSV Schema" />
            </AdminRoute>

            <TeamLeaderRoute exact path={TeamLeaderPaths.teamMemberCelebrateOutbox}>
              <CelebrateOutbox />
            </TeamLeaderRoute>
            <TeamLeaderRoute exact path={TeamLeaderPaths.teamMemberCelebrate}>
              <CelebrateTeam />
            </TeamLeaderRoute>
            <TeamLeaderRoute exact path={TeamLeaderPaths.teamMembers}>
              <TeamMembers />
            </TeamLeaderRoute>
            <TeamLeaderRoute exact path={TeamLeaderPaths.teamMemberView}>
              <TeamMemberView />
            </TeamLeaderRoute>
            <TeamLeaderRoute exact path={TeamLeaderPaths.teamMemberEdit}>
              <TeamMemberEdit headerTitle="Edit Profile" entityType="Team Member" />
            </TeamLeaderRoute>

            <ProtectedRoute exact path={RootPaths.achievedGoals}>
              <AchievedGoals />
            </ProtectedRoute>
            <ProtectedRoute exact path={RootPaths.redeemedPerks}>
              <RedeemedPerks />
            </ProtectedRoute>
            <ProtectedRoute exact path={RootPaths.userProfile}>
              <UserProfile />
            </ProtectedRoute>
            <ProtectedRoute exact path={RootPaths.verifyEmail}>
              <VerifyEmail />
            </ProtectedRoute>
            <ProtectedRoute exact path={ChildPaths.suggestPerk}>
              <SuggestPerk />
            </ProtectedRoute>
            <ProtectedRoute exact path={`${RootPaths.perks}/:id`}>
              <Perk />
            </ProtectedRoute>
            <ProtectedRoute exact path={RootPaths.perks}>
              <Perks />
            </ProtectedRoute>
            <ProtectedRoute exact path={`${RootPaths.jackpot}/:id`}>
              <JackpotPrize />
            </ProtectedRoute>
            <ProtectedRoute exact path={RootPaths.jackpot}>
              <Jackpot />
            </ProtectedRoute>
            <ProtectedRoute exact path={RootPaths.jackpotHistory}>
              <JackpotHistory />
            </ProtectedRoute>
            <ProtectedRoute exact path={RootPaths.help}>
              <Help />
            </ProtectedRoute>
            <ProtectedRoute exact path={ChildPaths.goalsOnDate}>
              <Goals />
            </ProtectedRoute>
            <ProtectedRoute exact path={RootPaths.home}>
              <Home />
            </ProtectedRoute>
            <ProtectedRoute path="*">
              <Error404 />
            </ProtectedRoute>
          </Switch>
          {/**
           * The LaunchDarklyProvider component requires 2 or more components to be rendered as children
           * so in addition to having a Switch component wrapped around all protected routes under the LaunchDarklyProvider,
           * we need to have an empty fragment component be rendered to trick LaunchDarklyProvider into thinking multiple
           * children are being rendered.
           */}
          <></>
        </LaunchDarklyProvider>
      </Switch>
    </>
  )
}
