import { useAppDispatch, useQueryParams } from './index'
import { UrlParams } from '../constants/UrlParams'
import { getImpersonator, impersonateWorkerThunk } from '../slices/impersonation'
import { fetchWhoamiThunk } from '../slices/whoami'
import { fetchStoresThunk } from '../slices/stores'
import { setDDUserSessionId } from '../utils/session'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { fetchUserStoreBrandsThunk } from 'slices/brands'
import { fetchStoreGroupsThunk } from 'slices/admin'

export const useSetUpProtectedRoute = (isAuthenticated: boolean) => {
  const dispatch = useAppDispatch()
  const query = useQueryParams()
  const isImpersonator = !!useSelector(getImpersonator)
  const hasImpersonationParams = useMemo(() => {
    return (
      query.get(UrlParams.impersonationEmploymentId) &&
      query.get(UrlParams.encryptedUserId)
    )
  }, [query])

  const fetchStartupWithImpersonation = async () => {
    if (isAuthenticated || hasImpersonationParams) {
      if (hasImpersonationParams && !isImpersonator) {
        const impersonation_employment = query.get(UrlParams.impersonationEmploymentId)
        const encrypted_user_id = query.get(UrlParams.encryptedUserId)
        if (impersonation_employment && encrypted_user_id) {
          await dispatch(
            impersonateWorkerThunk({
              employment_id: impersonation_employment,
              user_id: encrypted_user_id
            })
          )
        }
      }
      await dispatch(fetchWhoamiThunk({}))
      await dispatch(fetchUserStoreBrandsThunk({ getCachedResults: false }))
      await dispatch(fetchStoreGroupsThunk({getCachedResults: false }))
      await dispatch(fetchStoresThunk({ getCachedResults: true }))
    }
    setDDUserSessionId()
  }

  return {
    fetchStartupWithImpersonation
  }

}
