import { LDProvider } from 'launchdarkly-react-client-sdk'
import { useSelector } from 'react-redux'
import { getIsAuthenticated } from './slices/auth'
import { useSetUpProtectedRoute } from './hooks/useSetUpProtectedRoute'
import useSetUpUser from './hooks/useSetUpUser'
import { ReactElement, useEffect } from 'react'

export const LaunchDarklyProvider = ({children}:{children: ReactElement[]}) => {
  const isAuthenticated = useSelector(getIsAuthenticated)
  const {fetchStartupWithImpersonation} = useSetUpProtectedRoute(isAuthenticated)
  const {getLDUserContext} = useSetUpUser(isAuthenticated)
  useEffect(() => {
    fetchStartupWithImpersonation()
  }, [isAuthenticated])

  return <LDProvider clientSideID={process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID || ''} deferInitialization={true}
                    context={getLDUserContext()}>{children}</LDProvider>
}