import useAppDispatch from './useAppDispatch'

import { fetchAuthTokenThunk, logoutAction } from 'slices/auth'

import { RootPaths } from 'utils/helpers'
import { logError, logInfo } from 'utils/logger'
import { setDDUserSessionId } from 'utils/session'

import useCustomRedirect from './useRedirect'

export default async function useAuthenticateUser(isAuthenticated: boolean, loginToken: string, storedLoginToken: string) {
  const dispatch = useAppDispatch()
  const redirectToHome = useCustomRedirect(RootPaths.home)
  const redirectToLogin = useCustomRedirect(RootPaths.welcome)

  const authenticateUser = async () => {
    try {
      // see https://redux-toolkit.js.org/api/createAsyncThunk#unwrapping-result-actions
      await dispatch(fetchAuthTokenThunk(loginToken)).unwrap()
      logInfo(
        `User has successfully authenticated! Attemping to redirect to home page`
      )
      setDDUserSessionId(true)
      redirectToHome()
    } catch (error) {
      logError(
        `Unable to authenticate user! Redirecting to login... ${
          (error as Error)?.message
        }`,
        {
          error
        }
      )
      dispatch(logoutAction())
      redirectToLogin()
    }
  }

  // Only login if we correctly received the login token from params
  if (loginToken) {
    if (isAuthenticated && loginToken === storedLoginToken) {
      // redirect to home
      logInfo('User is already authenticated! Skipping auth flow...', {
        isAuthenticated,
        loginToken
      })
      setDDUserSessionId()
      redirectToHome()
    } else {
      await authenticateUser()
    }
  }
}
