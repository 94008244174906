import { createSlice, isAnyOf } from '@reduxjs/toolkit'

import { AuthTokensDTO, Impersonator } from 'types'

import { IMPERSONATOR, requestState, RequestState } from 'slices/constants'

import { fetchAuthTokenThunk, refreshTokenThunk } from 'slices/auth/actions'
import { handleRequestFulfilled, handleRequestRejected } from 'slices/utils'

import { impersonateWorkerThunk } from './actions'

export interface ImpersonatorState extends RequestState {
  impersonator: Impersonator | null
}

const initialState: ImpersonatorState = {
  impersonator: null,
  ...requestState
}

export const impersonatorSlice = createSlice({
  name: IMPERSONATOR,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(impersonateWorkerThunk.pending, (state) => {
        state.isLoading = true
      })
      .addCase(impersonateWorkerThunk.fulfilled, (state, action) => {
        const { impersonator } = action.payload as AuthTokensDTO & {
          impersonator: Impersonator
        }
        state.impersonator = impersonator
        handleRequestFulfilled(state)
      })
      .addCase(impersonateWorkerThunk.rejected, (state, action) => {
        const payload = state.impersonator?.encrypted_id
          ? 'You are already impersonating someone. Please drop the impersonation and try again.'
          : state.error
        handleRequestRejected(state, { ...action, payload })
      })
      .addMatcher(
        isAnyOf(fetchAuthTokenThunk.fulfilled, refreshTokenThunk.fulfilled),
        (state) => {
          state.impersonator = null
        }
      )
  }
})

export default impersonatorSlice.reducer
