// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import setupDataDogWithConfig from 'setupDatadog'
import { getThemeFromBrand } from 'theme'

import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material/styles'
import StylesProvider from '@mui/styles/StylesProvider'

import { StrictMode, useMemo } from 'react'
import ReactDOM from 'react-dom'
import { Provider, useSelector } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import { PersistGate } from 'redux-persist/integration/react'

/****** ATTENTION: This import MUST come before any selector imports ******/
import V4App from './App'

import { Banner } from './components'
import { OptedOutBanner } from 'components'
import ErrorBoundary from 'components/ErrorBoundary'
import { GlobalModalProvider } from 'components/modals'

import { getSelectedStoreBrand } from 'slices/storeSwitcher'

import { persistedStore, reduxStore } from './reduxStore'

import reportWebVitals from './reportWebVitals'

import './i18n/config'
import './index.css'

declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme {}
}

setupDataDogWithConfig()

const App = () => {
  const brand = useSelector(getSelectedStoreBrand)
  const theme = useMemo(() => {
    return getThemeFromBrand(brand)
  }, [brand])

  const statusBanner = <Banner>{process.env.REACT_APP_STATUS_MSG}</Banner>

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {statusBanner}
        <OptedOutBanner />
        <GlobalModalProvider>
          <V4App />
        </GlobalModalProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

const AppContainer = () => (
  <Provider store={reduxStore}>
    <PersistGate loading={null} persistor={persistedStore}>
      <ErrorBoundary>
        <BrowserRouter>
            <StylesProvider injectFirst>
              <App />
            </StylesProvider>
        </BrowserRouter>
      </ErrorBoundary>
    </PersistGate>
  </Provider>
)

ReactDOM.render(
  <StrictMode>
    <AppContainer />
  </StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
