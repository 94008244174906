import { OTPChannel } from 'pages/login/constants'

export enum PreferredLanguage {
  english = 'en',
  spanish = 'es'
}

export enum MessageFrequency {
  never = 'never',
  weekly = 'weekly',
  daily = 'daily'
}

export interface UserSettingsV2 {
  messages_frequency?: MessageFrequency
  language?: PreferredLanguage
  display_name?: string
  birthday?: string
}

export interface UserSettingsV3 {
  sms_frequency?: MessageFrequency
  language?: PreferredLanguage
  display_name?: string
  birthday?: string
}

export interface UserSettingsV4 {
  sms_frequency?: MessageFrequency
  language?: PreferredLanguage
  display_name?: string
  birthday: string | null
  email: string | null
  resend_verification_email?: boolean
}

export interface SendVerificationEmailRequest {
  channel: OTPChannel
  to: string
}

export interface VerifyEmailRequest {
  email: string
  code: string
}

export interface VerifyEmailResponse {
  is_email_verified: boolean
  message: string
}
