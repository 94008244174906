/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: any; output: any; }
  DateTime: { input: any; output: any; }
  UUID: { input: any; output: any; }
  Void: { input: any; output: any; }
};

export enum CompletionCadence {
  Custom = 'custom',
  Daily = 'daily',
  Monthly = 'monthly',
  Weekly = 'weekly'
}

export enum CompletionState {
  AwaitingData = 'awaiting_data',
  Complete = 'complete',
  Incomplete = 'incomplete'
}

export enum Day {
  Friday = 'friday',
  Monday = 'monday',
  Saturday = 'saturday',
  Sunday = 'sunday',
  Thursday = 'thursday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday'
}

export type Goal = {
  completionCadence: CompletionCadence;
  createdBy?: Maybe<User>;
  createdOn: Scalars['Date']['output'];
  description: Scalars['String']['output'];
  endDate?: Maybe<Scalars['Date']['output']>;
  goalType: GoalType;
  id: Scalars['ID']['output'];
  kaiserGoalId?: Maybe<Scalars['String']['output']>;
  maxCompletions: Scalars['Int']['output'];
  modifiedBy?: Maybe<User>;
  modifiedOn: Scalars['Date']['output'];
  name: Scalars['String']['output'];
  points?: Maybe<Scalars['Int']['output']>;
  startDate: Scalars['Date']['output'];
  status?: Maybe<StatusState>;
  stores: Array<Store>;
};

export enum GoalFrequency {
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY'
}

export type GoalProgressInputArgs = {
  dataId: Scalars['String']['input'];
  goalId: Scalars['UUID']['input'];
  progressId: Scalars['UUID']['input'];
  state: CompletionState;
  timestamp: Scalars['DateTime']['input'];
  value: Scalars['String']['input'];
};

export type GoalState = {
  __typename?: 'GoalState';
  goalDescription?: Maybe<Scalars['String']['output']>;
  goalId?: Maybe<Scalars['ID']['output']>;
  goalName?: Maybe<Scalars['String']['output']>;
  goalPoints?: Maybe<Scalars['Int']['output']>;
  goalType?: Maybe<GoalType>;
  kaiserGoalId?: Maybe<Scalars['UUID']['output']>;
  state?: Maybe<CompletionState>;
};

export enum GoalType {
  WorkAShift = 'work_a_shift',
  WorkATimeBasedShift = 'work_a_time_based_shift'
}

export type KaiserGoal = {
  __typename?: 'KaiserGoal';
  description?: Maybe<Scalars['String']['output']>;
  frequency?: Maybe<GoalFrequency>;
  goalType?: Maybe<GoalType>;
  icon?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  parent?: Maybe<Scalars['ID']['output']>;
  points?: Maybe<Scalars['Int']['output']>;
  result?: Maybe<Result>;
  storeGroup?: Maybe<StoreGroup>;
  title?: Maybe<Scalars['String']['output']>;
};

export enum LegacyGoalType {
  Checkin = 'CHECKIN',
  Greater = 'GREATER',
  InBetween = 'IN_BETWEEN',
  Less = 'LESS',
  Question = 'QUESTION'
}

export type Mutation = {
  __typename?: 'Mutation';
  activateGoal?: Maybe<Scalars['Void']['output']>;
  addGoalProgressForEmployee?: Maybe<Scalars['Void']['output']>;
  addGoalProgressForUser?: Maybe<Scalars['Void']['output']>;
  archiveGoal?: Maybe<Scalars['Void']['output']>;
  createWorkAShiftGoalForStoreGroup?: Maybe<WorkAShiftGoal>;
  createWorkAShiftGoalInternalForStoreGroup?: Maybe<WorkAShiftGoal>;
  createWorkATimeBasedShiftGoalForStoreGroup?: Maybe<WorkATimeBasedShiftGoal>;
  createWorkATimeBasedShiftGoalInternalForStoreGroup?: Maybe<WorkATimeBasedShiftGoal>;
  updateStatus?: Maybe<Goal>;
  updateWorkAShiftGoal?: Maybe<WorkAShiftGoal>;
  updateWorkAShiftGoalInternal?: Maybe<WorkAShiftGoal>;
  updateWorkATimeBasedShiftGoal?: Maybe<WorkATimeBasedShiftGoal>;
  updateWorkATimeBasedShiftGoalInternal?: Maybe<WorkATimeBasedShiftGoal>;
};


export type MutationActivateGoalArgs = {
  goalId: Scalars['UUID']['input'];
};


export type MutationAddGoalProgressForEmployeeArgs = {
  employmentId: Scalars['UUID']['input'];
  progress: WorkAShiftGoalProgressInputArgs;
  userProfileId: Scalars['UUID']['input'];
};


export type MutationAddGoalProgressForUserArgs = {
  employmentId: Scalars['UUID']['input'];
  progress: GoalProgressInputArgs;
  userProfileId: Scalars['UUID']['input'];
};


export type MutationArchiveGoalArgs = {
  goalId: Scalars['UUID']['input'];
};


export type MutationCreateWorkAShiftGoalForStoreGroupArgs = {
  args?: InputMaybe<WorkAShiftGoalInputArgs>;
  storeGroupId: Scalars['ID']['input'];
};


export type MutationCreateWorkAShiftGoalInternalForStoreGroupArgs = {
  args?: InputMaybe<WorkAShiftGoalInternalInputArgs>;
  storeGroupId: Scalars['ID']['input'];
};


export type MutationCreateWorkATimeBasedShiftGoalForStoreGroupArgs = {
  args?: InputMaybe<WorkATimeBasedShiftGoalInputArgs>;
  storeGroupId: Scalars['ID']['input'];
};


export type MutationCreateWorkATimeBasedShiftGoalInternalForStoreGroupArgs = {
  args?: InputMaybe<WorkATimeBasedShiftGoalInternalInputArgs>;
  storeGroupId: Scalars['ID']['input'];
};


export type MutationUpdateStatusArgs = {
  evaluation: StatusLeverValue;
  goalId: Scalars['UUID']['input'];
  pointsIssuance: StatusLeverValue;
};


export type MutationUpdateWorkAShiftGoalArgs = {
  args?: InputMaybe<WorkAShiftGoalInputArgs>;
  goalId: Scalars['ID']['input'];
};


export type MutationUpdateWorkAShiftGoalInternalArgs = {
  args?: InputMaybe<WorkAShiftGoalInternalInputArgs>;
  goalId: Scalars['UUID']['input'];
};


export type MutationUpdateWorkATimeBasedShiftGoalArgs = {
  args?: InputMaybe<WorkATimeBasedShiftGoalInputArgs>;
  goalId: Scalars['UUID']['input'];
};


export type MutationUpdateWorkATimeBasedShiftGoalInternalArgs = {
  args?: InputMaybe<WorkATimeBasedShiftGoalInternalInputArgs>;
  goalId: Scalars['UUID']['input'];
};

export type PagedGoals = {
  __typename?: 'PagedGoals';
  goals: Array<Goal>;
  pagingInfo: PagingInfo;
};

export type PagingInfo = {
  __typename?: 'PagingInfo';
  offset: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type Query = {
  __typename?: 'Query';
  getGoal?: Maybe<Goal>;
  getGoalStatesForEmployeeOnDate: Array<GoalState>;
  getGoalsForStoreGroup?: Maybe<PagedGoals>;
  getGoalsForStoreOnDate: Array<KaiserGoal>;
};


export type QueryGetGoalArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetGoalStatesForEmployeeOnDateArgs = {
  date: Scalars['Date']['input'];
  storeGroupId: Scalars['UUID']['input'];
  userProfileId: Scalars['UUID']['input'];
};


export type QueryGetGoalsForStoreGroupArgs = {
  offset?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  searchQuery?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Status>;
  storeGroupId: Scalars['ID']['input'];
};


export type QueryGetGoalsForStoreOnDateArgs = {
  date?: InputMaybe<Scalars['String']['input']>;
  storeId?: InputMaybe<Scalars['ID']['input']>;
};

export type Result = {
  __typename?: 'Result';
  completed?: Maybe<Scalars['Boolean']['output']>;
  result?: Maybe<Scalars['String']['output']>;
  resultStatus?: Maybe<Scalars['String']['output']>;
  resultText?: Maybe<Scalars['String']['output']>;
};

export enum Status {
  Active = 'active',
  Archived = 'archived',
  Testing = 'testing'
}

export enum StatusLeverValue {
  InternalTesting = 'internal_testing',
  No = 'no',
  Yes = 'yes'
}

export type StatusState = {
  __typename?: 'StatusState';
  modifiedBy?: Maybe<User>;
  modifiedOn: Scalars['Date']['output'];
  status?: Maybe<Status>;
};

export type Store = {
  __typename?: 'Store';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type StoreGroup = {
  __typename?: 'StoreGroup';
  id?: Maybe<Scalars['ID']['output']>;
  stores?: Maybe<Array<Maybe<Store>>>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID']['output'];
};

export type WorkAShiftGoal = Goal & {
  __typename?: 'WorkAShiftGoal';
  completionCadence: CompletionCadence;
  createdBy?: Maybe<User>;
  createdOn: Scalars['Date']['output'];
  days: Array<Day>;
  description: Scalars['String']['output'];
  endDate?: Maybe<Scalars['Date']['output']>;
  goalType: GoalType;
  id: Scalars['ID']['output'];
  kaiserGoalId?: Maybe<Scalars['String']['output']>;
  maxCompletions: Scalars['Int']['output'];
  modifiedBy?: Maybe<User>;
  modifiedOn: Scalars['Date']['output'];
  name: Scalars['String']['output'];
  points?: Maybe<Scalars['Int']['output']>;
  startDate: Scalars['Date']['output'];
  status?: Maybe<StatusState>;
  stores: Array<Store>;
};

export type WorkAShiftGoalInputArgs = {
  completionCadence?: InputMaybe<CompletionCadence>;
  days: Array<Day>;
  description: Scalars['String']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  maxCompletions?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  startDate: Scalars['Date']['input'];
};

export type WorkAShiftGoalInternalInputArgs = {
  completionCadence?: InputMaybe<CompletionCadence>;
  days: Array<Day>;
  description: Scalars['String']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  kaiserGoalId?: InputMaybe<Scalars['String']['input']>;
  maxCompletions?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  points: Scalars['Int']['input'];
  startDate: Scalars['Date']['input'];
};

export type WorkAShiftGoalProgressInputArgs = {
  dataId: Scalars['String']['input'];
  goalId: Scalars['UUID']['input'];
  progressId: Scalars['UUID']['input'];
  shiftStart: Scalars['DateTime']['input'];
  state: CompletionState;
  timestamp: Scalars['DateTime']['input'];
};

export type WorkATimeBasedShiftGoal = Goal & {
  __typename?: 'WorkATimeBasedShiftGoal';
  completionCadence: CompletionCadence;
  createdBy?: Maybe<User>;
  createdOn: Scalars['Date']['output'];
  days: Array<Day>;
  description: Scalars['String']['output'];
  endDate?: Maybe<Scalars['Date']['output']>;
  goalType: GoalType;
  id: Scalars['ID']['output'];
  kaiserGoalId?: Maybe<Scalars['String']['output']>;
  maxCompletions: Scalars['Int']['output'];
  modifiedBy?: Maybe<User>;
  modifiedOn: Scalars['Date']['output'];
  name: Scalars['String']['output'];
  points?: Maybe<Scalars['Int']['output']>;
  shiftEndTime: Scalars['String']['output'];
  shiftStartTime: Scalars['String']['output'];
  startDate: Scalars['Date']['output'];
  status?: Maybe<StatusState>;
  stores: Array<Store>;
};

export type WorkATimeBasedShiftGoalInputArgs = {
  completionCadence?: InputMaybe<CompletionCadence>;
  days: Array<Day>;
  description: Scalars['String']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  maxCompletions?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  shiftEndTime: Scalars['String']['input'];
  shiftStartTime: Scalars['String']['input'];
  startDate: Scalars['Date']['input'];
};

export type WorkATimeBasedShiftGoalInternalInputArgs = {
  completionCadence?: InputMaybe<CompletionCadence>;
  days: Array<Day>;
  description: Scalars['String']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  kaiserGoalId?: InputMaybe<Scalars['String']['input']>;
  maxCompletions?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  points: Scalars['Int']['input'];
  shiftEndTime: Scalars['String']['input'];
  shiftStartTime: Scalars['String']['input'];
  startDate: Scalars['Date']['input'];
};

export type GetGoalStatesForEmployeeOnDateQueryVariables = Exact<{
  storeGroupId: Scalars['UUID']['input'];
  userProfileId: Scalars['UUID']['input'];
  date: Scalars['Date']['input'];
}>;


export type GetGoalStatesForEmployeeOnDateQuery = { __typename?: 'Query', getGoalStatesForEmployeeOnDate: Array<{ __typename?: 'GoalState', goalDescription?: string | null, goalId?: string | null, goalName?: string | null, goalPoints?: number | null, goalType?: GoalType | null, kaiserGoalId?: any | null, state?: CompletionState | null }> };


export const GetGoalStatesForEmployeeOnDateDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetGoalStatesForEmployeeOnDate"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"storeGroupId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UUID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"userProfileId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UUID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"date"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Date"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getGoalStatesForEmployeeOnDate"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"date"},"value":{"kind":"Variable","name":{"kind":"Name","value":"date"}}},{"kind":"Argument","name":{"kind":"Name","value":"userProfileId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"userProfileId"}}},{"kind":"Argument","name":{"kind":"Name","value":"storeGroupId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"storeGroupId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"goalDescription"}},{"kind":"Field","name":{"kind":"Name","value":"goalId"}},{"kind":"Field","name":{"kind":"Name","value":"goalName"}},{"kind":"Field","name":{"kind":"Name","value":"goalPoints"}},{"kind":"Field","name":{"kind":"Name","value":"goalType"}},{"kind":"Field","name":{"kind":"Name","value":"kaiserGoalId"}},{"kind":"Field","name":{"kind":"Name","value":"state"}}]}}]}}]} as unknown as DocumentNode<GetGoalStatesForEmployeeOnDateQuery, GetGoalStatesForEmployeeOnDateQueryVariables>;