import { GraphQLClient } from 'graphql-request'

import { getEnvGraphQLAPIHost } from 'utils/helpers'

const requestMiddleware = () => {
  const accessToken: string = localStorage.getItem('accessToken') as string
  return {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `JWT ${accessToken}`
    }
  }
}

export const _client = () => {
  return new GraphQLClient(getEnvGraphQLAPIHost(), requestMiddleware())
}
