import type { GetGoalStatesForEmployeeOnDateQuery } from 'generated/graphql'

import { fetchRecords } from 'api'
import { getGoalStatesForEmployeeOnDate } from 'api/handlers'

import {
  FetchCachedThunkProps,
  FetchGoalsOnDateThunkProps,
  Goal,
  FetchGoalStatesForEmployeeOnDateThunkProps
} from 'types'

import { GOALS } from 'slices/constants'

import { getImpersonator } from 'slices/impersonation'
import { getDefaultSelectedStore } from 'slices/storeSwitcher'
import { authenticatedThunkCreator, shouldRefetch } from 'slices/utils'

import { getAchievedGoals, getGoalsOnDate } from './selectors'

import { getTodaysDate } from 'utils/form'
import { conditionalObject } from 'utils/helpers'

import { RootState } from 'reduxStore'

export const fetchGoalsOnDateThunk = authenticatedThunkCreator<
  Goal[],
  FetchGoalsOnDateThunkProps
>(
  `${GOALS}/fetchGoalsOnDateThunk`,
  async (authedDTO, { getState }) => {
    const state = getState() as RootState
    const selectedStoreId = getDefaultSelectedStore(state)?.id
    const date = authedDTO?.date || getTodaysDate()
    const goalsResponse: { goals: Goal[] } = await fetchRecords(
      {
        ...authedDTO,
        queryParams: {
          date,
          ...conditionalObject({ store_id: selectedStoreId }, selectedStoreId)
        }
      },
      'goalsOnDate'
    )
    return goalsResponse.goals
  },
  {
    condition: (options, { getState }) => {
      const state = getState() as RootState
      const date = options.date
      const impersonator = getImpersonator(state)
      const prevList = getGoalsOnDate(state, date) || []
      const selectedStoreId = getDefaultSelectedStore(state)?.id
      //refetch if our goals in the cache for a date are not for the current store
      if (prevList.length && prevList[0].store_id !== selectedStoreId) return true
      return shouldRefetch(false, prevList, impersonator, options)
    }
  }
)

export const fetchAchievedGoals = authenticatedThunkCreator<
  Goal[],
  FetchCachedThunkProps
>(
  `${GOALS}/fetchAchievedGoals`,
  async (authedDTO) => {
    const achievedGoalsObj: { goals: Goal[] } = await fetchRecords(
      authedDTO,
      'achievedGoals'
    )
    return achievedGoalsObj.goals
  },
  {
    condition: (options = {}, { getState }) => {
      const state = getState() as RootState
      const isUpdating = false
      const impersonator = getImpersonator(state)
      const prevList = getAchievedGoals(state)
      return shouldRefetch(isUpdating, prevList, impersonator, options)
    }
  }
)

export const fetchGoalStatesForEmployeeOnDate = authenticatedThunkCreator<
  GetGoalStatesForEmployeeOnDateQuery,
  FetchGoalStatesForEmployeeOnDateThunkProps
>(
  `${GOALS}/fetchGoalStatesForEmployeeOnDate`,
  async ({ storeGroupId, userProfileId, date }) => {
    return await getGoalStatesForEmployeeOnDate({ storeGroupId, userProfileId, date })
  }
)
