import { createAsyncThunk } from '@reduxjs/toolkit'

import { fetchAuthToken, refreshToken } from 'api'

import { AuthTokensDTO, LoginToken } from 'types'

import { AUTH } from 'slices/constants'

import { RootState } from '../../reduxStore'

export const logoutAction = () => ({
  type: 'LOGOUT'
})

export const fetchAuthTokenThunk = createAsyncThunk(
  `${AUTH}/fetchAuthToken`,
  async (loginToken: LoginToken) => {
    const tokens: AuthTokensDTO = await fetchAuthToken(loginToken)
    return tokens
  }
)

export const refreshTokenThunk = createAsyncThunk(
  `${AUTH}/refreshAuthToken`,
  async (_, { getState }) => {
    const state = getState() as RootState
    const token = state.auth.refreshToken
    const tokens: AuthTokensDTO = await refreshToken(token as string)
    return tokens
  }
)
