import { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'

import { cleanPhoneNumber } from 'pages/login/utils'

import { useIsOnSmallDevice } from 'hooks/mediaQueries'
import { useNavigation } from 'hooks/useNavigation'

import { formatPhoneNumber, getLoginWithTypePath } from 'utils/helpers'

import {
  disabledButtonStyles,
  StyledInputContainer,
  StyledInternationalCountryCode,
  StyledNextButton,
  StyledPhoneContainer,
  StyledInput
} from '../styles'

export interface PhoneNumberInputProps {
  showNextButton?: boolean
  shouldShowError?: boolean
  loginError?: string | null
  value?: string
  handleChange?: (event: ChangeEvent<HTMLInputElement>) => void
}

export const PhoneNumberInput = ({
  showNextButton = false,
  shouldShowError = false,
  value,
  loginError,
  handleChange
}: PhoneNumberInputProps) => {
  const { t } = useTranslation()
  const isOnSmallDevice = useIsOnSmallDevice()
  const navigation = useNavigation()
  const isDisabled = !!loginError || cleanPhoneNumber(value ?? '')?.length !== 10

  const handleDisabledFocus = () => {
    // We are creating a hacky input that essentially acts as a glorified button
    // As soon as the user clicks on the input, or it gets focus
    // Immediately navigate to the actual form with the enabled version of this input
    navigation.push(getLoginWithTypePath('phone'))
  }

  return (
    <StyledPhoneContainer isOnSmallDevice={isOnSmallDevice} error={shouldShowError}>
      <StyledInputContainer>
        <StyledInternationalCountryCode
          placeholder="+1"
          disabled
          isOnSmallDevice={isOnSmallDevice}
          data-testid="InternationalCountryCodeInput"
        />
        {showNextButton ? (
          <StyledInput
            type="tel"
            placeholder={t('pages.login.form.phoneNumberInputPlaceholder')}
            onChange={handleChange}
            value={formatPhoneNumber(value ?? '')}
            autoFocus
            isOnSmallDevice={isOnSmallDevice}
            data-testid="PhoneNumberInput-Enabled"
          />
        ) : (
          <StyledInput
            type="tel"
            placeholder={t('pages.login.form.phoneNumberInputPlaceholder')}
            onFocus={handleDisabledFocus}
            isOnSmallDevice={isOnSmallDevice}
            data-testid="PhoneNumberInput-Disabled"
          />
        )}
      </StyledInputContainer>
      {showNextButton && (
        <StyledNextButton
          variant="contained"
          fullWidth
          size={isOnSmallDevice ? 'small' : 'medium'}
          disabled={isDisabled}
          type="submit"
          style={isDisabled ? disabledButtonStyles : undefined}
          data-testid="NextButton"
        >
          {t('pages.login.form.enabled.nextButton')}
        </StyledNextButton>
      )}
    </StyledPhoneContainer>
  )
}
