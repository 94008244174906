import { createSlice, isAnyOf } from '@reduxjs/toolkit'

import { AuthTokensDTO, Impersonator, Whoami } from 'types'

import { AUTH, requestState, RequestState } from 'slices/constants'

import { impersonateWorkerThunk } from 'slices/impersonation/actions'
import { handleRequestFulfilled } from 'slices/utils'

import { fetchAuthTokenThunk, refreshTokenThunk } from './actions'

export interface AuthState extends RequestState {
  accessToken: string
  refreshToken: string
  loginToken: string
  whoami: Whoami | null
}

const initialState: AuthState = {
  accessToken: '',
  refreshToken: '',
  loginToken: '',
  whoami: null,
  ...requestState
}

export const authSlice = createSlice({
  name: AUTH,
  initialState,
  reducers: {
    dismissError(state) {
      state.error = ''
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(impersonateWorkerThunk.fulfilled, (state, action) => {
        const { access } = action.payload as AuthTokensDTO & {
          impersonator: Impersonator
        }
        state.accessToken = access
        state.refreshToken = '' // explicitly disable refreshing when impersonating
        localStorage.setItem('accessToken', access)
        localStorage.setItem('refreshToken', '')
        handleRequestFulfilled(state)
      })
      .addMatcher(
        isAnyOf(fetchAuthTokenThunk.fulfilled, refreshTokenThunk.fulfilled),
        (state, action) => {
          const tokens = action.payload as AuthTokensDTO
          const loginToken = action.meta?.arg || state.loginToken

          state.loginToken = loginToken
          state.accessToken = tokens?.access
          state.refreshToken = tokens?.refresh

          localStorage.setItem('loginToken', loginToken)
          localStorage.setItem('accessToken', tokens?.access)
          localStorage.setItem('refreshToken', tokens?.refresh)

          handleRequestFulfilled(state)
        }
      )
  }
})

export const { dismissError } = authSlice.actions

export default authSlice.reducer
