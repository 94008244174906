import { UrlParams } from 'constants/UrlParams'

import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Route, RouteProps } from 'react-router-dom'

import { Onboarding } from 'pages/onboarding'

import { useAppDispatch, useCustomRedirect, useQueryParams } from 'hooks'

import {
  getAuthErrorMsg,
  getAuthHasLoaded,
  getIsAuthenticated,
  logoutAction
} from 'slices/auth'
import { getImpersonatorErrorMsg, getImpersonatorLoaded } from 'slices/impersonation'
import { getStoresError, selectStoresHasLoaded } from 'slices/stores'
import { getShowOnboarding, getWhoami, getWhoamiHasLoaded } from 'slices/whoami'
import { getWhoamiHasError } from 'slices/whoami/selectors'

import { RootPaths } from 'utils/helpers'
import { logError } from 'utils/logger'

import ImpersonatorBanner from './impersonatorBanner'
import Splash from './splash'

export default function ProtectedRoute({ children, ...restProps }: RouteProps) {
  const dispatch = useAppDispatch()

  const shouldShowOnboarding = useSelector(getShowOnboarding)

  const isAuthenticated = useSelector(getIsAuthenticated)
  const hasAuthLoaded = useSelector(getAuthHasLoaded)
  const authError = useSelector(getAuthErrorMsg)

  const whoami = useSelector(getWhoami)
  const hasWhoamiLoaded = useSelector(getWhoamiHasLoaded)
  const whoamiError = useSelector(getWhoamiHasError)

  const hasStoresLoaded = useSelector(selectStoresHasLoaded)
  const storesError = useSelector(getStoresError)

  const hasImpersonationLoaded = useSelector(getImpersonatorLoaded)
  const impersonationError = useSelector(getImpersonatorErrorMsg)

  const query = useQueryParams()
  const hasImpersonationParams = useMemo(() => {
    return (
      query.get(UrlParams.impersonationEmploymentId) &&
      query.get(UrlParams.encryptedUserId)
    )
  }, [query])

  const redirectToLogin = useCustomRedirect(RootPaths.welcome)

  // If we are trying to impersonate someone, then wait for the above impersonation
  // call to finish to avoid prematurely redirecting a user to the login screen
  // if they are not authenticated
  if (hasImpersonationParams && !hasImpersonationLoaded) {
    return <Splash />
  }

  // If we have no whoami data AND the user is not authenticated, we
  // can safely assume they have logged out. We cannot use `!hasAuthLoaded`
  // since it gets flushed everytime we reset the redux state/log the user out
  if (whoami === null && !isAuthenticated) {
    return redirectToLogin({
      returnAsComponent: true
    })!
  }

  // Reset the redux state and redirect to login if user is not authenticated
  if (hasAuthLoaded && !isAuthenticated) {
    dispatch(logoutAction())
    return redirectToLogin({
      returnAsComponent: true
    })!
  }

  // Check for error states
  if (
    (hasImpersonationParams && impersonationError) ||
    authError ||
    whoamiError ||
    storesError
  ) {
    logError('Failed to load protected route: ', {
      hasImpersonationParams,
      impersonationError,
      authError,
      whoamiError,
      storesError
    })
    return redirectToLogin({
      returnAsComponent: true
    })!
  }

  // Check for loading states
  if (!hasAuthLoaded || !hasWhoamiLoaded || !hasStoresLoaded) {
    return <Splash />
  }

  return (
    <Route {...restProps}>
      <>
        <ImpersonatorBanner />
        {shouldShowOnboarding ? <Onboarding /> : children}
      </>
    </Route>
  )
}
