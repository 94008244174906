import { AUTH_PATH_REGEX } from 'constants/auth'

import { Grid, Link } from '@mui/material'

import {
  ChangeEvent,
  KeyboardEvent,
  FormEvent,
  useEffect,
  useRef,
  useState,
  ClipboardEvent
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Redirect, useLocation } from 'react-router-dom'

import { sendOTPV2, verifyOTP } from 'api'

import { LoginStateParams } from 'types'

import { LoginType, OTPChannel } from 'pages/login/constants'

import useAppDispatch from 'hooks/useAppDispatch'

import { LoginHelp } from '../../components'
import { StyledPhoneContainer } from '../../components/styles'

import { useNavigation } from 'hooks/useNavigation'

import { setSnackbar } from 'slices/snackbar'

import { formatPhoneNumber, getLoginWithTypePath, RootPaths } from 'utils/helpers'

import {
  StyledOTPErrorText,
  StyledOTPHeader,
  StyledOTPHeaderText,
  StyledOTPInput,
  StyledOTPResendCode,
  StyledOTPText
} from './otp.styles'

import LoginFormLayout from '../LoginFormLayout'

const NUM_INPUTS = 6

export default function OTPLoginForm() {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [activeInputIndex, setActiveInputIndex] = useState(0)
  const [otpValue, setOTPValue] = useState('')
  const inputRef = useRef<HTMLInputElement>(null)
  const [submittingForm, setSubmittingForm] = useState(false)
  const [resendingOTP, setResendingOTP] = useState(false)
  const location = useLocation<Partial<LoginStateParams>>()
  const [loginError, setLoginError] = useState<string | null>()
  const navigation = useNavigation()

  const hasError = Boolean(loginError)
  const isResendDisabled = resendingOTP || submittingForm

  const loginType: LoginStateParams['loginType'] =
    location?.state?.loginType || LoginType.phone
  const loginValue = location?.state?.loginValue
  const isEmail = loginType === LoginType.email

  useEffect(() => {
    if (inputRef?.current) {
      const input = inputRef.current.querySelector('input')
      if (!input) return
      input.focus()
    }
  }, [activeInputIndex])

  useEffect(() => {
    if (otpValue && hasError) {
      // remove error if we changed any input
      setLoginError(null)
    }
  }, [otpValue, loginError])

  useEffect(() => {
    if (otpValue.length === NUM_INPUTS && !submittingForm) {
      handleSubmit()
    }
  }, [otpValue, submittingForm])

  useEffect(() => {
    let timer: NodeJS.Timeout
    if (resendingOTP) {
      timer = setTimeout(() => {
        setResendingOTP(false)
      }, 10000)
    }
    return () => clearTimeout(timer)
  }, [resendingOTP])

  if (!loginValue) {
    // Redirect to the first login screen if there is no state available
    // To avoid ppl manually going to this route via the URL with no data
    return <Redirect to={RootPaths.welcome} />
  }

  const focusNext = () => {
    setActiveInputIndex((i) => {
      if (i >= NUM_INPUTS - 1) {
        return i
      }
      return i + 1
    })
  }

  const focusPrevious = () => {
    setActiveInputIndex((i) => {
      if (i <= 0) {
        return i
      }
      return i - 1
    })
  }

  const resetOTPValue = () => {
    setOTPValue('')
    setActiveInputIndex(0)
  }

  const fillAllInputs = (newValues: string[]) => {
    if (newValues.length !== NUM_INPUTS) {
      // ONLY fill if we have the exact data we need
      return
    }
    const otp = otpValue.split('')
    for (let i = 0; i < NUM_INPUTS; i++) {
      otp[i] = newValues.shift() as string
    }
    setActiveInputIndex(NUM_INPUTS - 1)
    setOTPValue(otp.join(''))
  }

  const changeOTPAtCurrentIndex = (value: string) => {
    const newValues = value.split('')
    if (newValues.length === NUM_INPUTS) {
      fillAllInputs(newValues)
    } else {
      const otp = otpValue.split('')
      const latestVal = value.substring(value.length - 1)
      otp[activeInputIndex] = latestVal
      if (!value) {
        focusPrevious()
      } else {
        focusNext()
      }
      setOTPValue(otp.join(''))
    }
  }

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case 'Delete':
      case 'Backspace':
        changeOTPAtCurrentIndex('')
        break
      case 'ArrowLeft':
        focusPrevious()
        break
      case 'ArrowRight':
        focusNext()
        break
      case 'Tab':
        // Allow tabbing to the next input
        break
      case '0':
      case '1':
      case '2':
      case '3':
      case '4':
      case '5':
      case '6':
      case '7':
      case '8':
      case '9':
        // Will propogate and call the OnChange
        break
      case 'Enter':
        handleSubmit()
        break
      default:
        e.preventDefault()
        break
    }
  }

  const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = e.target
    if (value.trim().length >= 1) {
      changeOTPAtCurrentIndex(value)
    }
  }

  const handleSubmit = async () => {
    // Do not allow any submits if we are in the middle of submitting the form or we do not have data to submit
    if (submittingForm || !loginValue || !otpValue) return

    setSubmittingForm(true)
    try {
      const body = await verifyOTP(loginValue, otpValue, loginType)
      const tokenizedLink = body['tokenized_link']
      const pathMatches = tokenizedLink.match(AUTH_PATH_REGEX)
      if (pathMatches?.length) {
        const path = pathMatches[0]
        window.location.pathname = path
      }
    } catch (error) {
      setLoginError('pages.login.form.errors.incorrectCode')
    } finally {
      setSubmittingForm(false)
      resetOTPValue()
    }
  }

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    handleSubmit()
  }

  const onPaste = (e: ClipboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    e.preventDefault()
    if (submittingForm) {
      return
    }

    // Get pastedData in an array of max NUM_INPUTS
    const clipboardData: string | undefined = (e.clipboardData as DataTransfer).getData(
      'text/plain'
    )
    // Make sure we have actual data pasted
    if (!clipboardData) return
    const pastedOTP: string[] = clipboardData.slice(0, NUM_INPUTS).split('')
    // Make sure we have an exact match between the length of the pasted data and our expected OTP
    if (pastedOTP.length < NUM_INPUTS) return
    // Make sure we are inputting numeric values only
    const isAllNumeric = pastedOTP.every((x) => /\d/.test(x))
    if (!isAllNumeric) return
    fillAllInputs(pastedOTP)
  }

  const generateInputs = () => {
    return Array.from({ length: NUM_INPUTS }, (_, index) => (
      <Grid key={index} item xs={12 / NUM_INPUTS}>
        <StyledOTPInput
          ref={index === activeInputIndex ? inputRef : null}
          onChange={handleInputChange}
          disabled={submittingForm}
          error={hasError}
          inputProps={{
            type: 'number',
            inputMode: 'numeric',
            pattern: '\\d*',
            autoComplete: 'one-time-code',
            autoFocus: index === 0,
            onKeyDown,
            onPaste,
            min: 0,
            max: 9,
            step: 1,
            value: otpValue[index] ?? ''
          }}
        />
      </Grid>
    ))
  }

  const resendOTP = async () => {
    // User should not be able to spam resend
    if (isResendDisabled || !loginValue) return

    const channel = isEmail ? OTPChannel.email : OTPChannel.phone
    const res = await sendOTPV2({ channel, to: loginValue })

    setResendingOTP(true)
    dispatch(
      setSnackbar({
        open: true,
        type: 'success',
        message: t(`pages.login.form.otp.toasts.resendSuccess${loginType}`)
      })
    )
    if (!res.ok) {
      setLoginError(`pages.login.form.errors.${loginType}NotFoundError`)
    }
  }

  const handleGoBack = () => {
    navigation.replace({
      pathname: getLoginWithTypePath(loginType)
    })
  }

  return (
    <LoginFormLayout handleGoBack={handleGoBack}>
      <StyledOTPHeader>
        <StyledOTPHeaderText bold>
          {t('pages.login.form.otp.enterCode')}
        </StyledOTPHeaderText>
        {loginValue && (
          <StyledOTPHeaderText>{formatPhoneNumber(loginValue)}</StyledOTPHeaderText>
        )}
        <StyledOTPText>
          {t('pages.login.form.otp.validPhoneNotice')}
        </StyledOTPText>
      </StyledOTPHeader>
      <Grid container alignItems="center" justifyContent="center" textAlign="center">
        <Grid item xs={12} md={8}>
          <form onSubmit={onSubmit}>
            <StyledPhoneContainer error={hasError}>
              <Grid
                container
                justifyContent="space-around"
                alignItems="center"
                spacing={2}
                style={{ padding: '25px 0' }}
              >
                {generateInputs()}
              </Grid>
              <StyledOTPResendCode disabled={isResendDisabled}>
                <Trans
                  i18nKey="pages.login.form.otp.resend"
                  t={t}
                  components={[
                    <Link component="button" key="resendOtp" onClick={resendOTP} />
                  ]}
                />
              </StyledOTPResendCode>
            </StyledPhoneContainer>
            {hasError && <StyledOTPErrorText>{t(loginError!)}</StyledOTPErrorText>}
            <LoginHelp />
          </form>
        </Grid>
      </Grid>
    </LoginFormLayout>
  )
}
